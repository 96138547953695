<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    v-html="path"
    v-bind="$attrs"
  ></svg>
</template>

<script lang="ts" setup>
const props = defineProps({
  width: {
    type: [String, Number],
    required: true,
  },
  height: {
    type: [String, Number],
    required: true,
  },
  viewBox: {
    type: String,
  },
  path: {
    type: String,
    required: true,
  },
});

const viewBox = computed(() => {
  if (props.viewBox) {
    return props.viewBox;
  } else {
    return `0 0 ${props.width} ${props.height}`
  }
});
</script>
